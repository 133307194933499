<template>
  <div class="feild-input-group" :class="{ 'is-invalid': isInvalid }">
    <slot />
    <span class="error-msg">{{ errorMsg }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isInvalid: Boolean,
    errorMsg: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
