import { ref, computed, markRaw } from 'vue'

// Cells
import NetworkNamesInGroup from '../components/Grid/Cell/GroupSettingView/NetworkNamesInGroup.vue'
import Operation from '../components/Grid/Cell/GroupSettingView/Operation.vue'
import OperationHeader from '../components/Grid/Cell/GroupSettingView/OperationHeader.vue'

// 區域設定/網段群組設定列表中使用 Kendo Grid
const useGridInGroupSetting = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'GroupName',
      title: '群組名稱',
      width: '200px'
    },
    {
      field: 'Operation',
      title: '操作',
      width: '160px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'NetworkNamesInGroup',
      title: '群組內的網段',
      width: 'auto',
      cell: markRaw(NetworkNamesInGroup)
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const groupName = ['Default', 'Default Site']
    const networkNamesInGroup = [
      ['工程部'],
      [
        'Accounting Department',
        'C2960+24TCS',
        'Service Department',
        'DGS-105',
        '123455',
        'HP 1410-16G',
        '資訊部'
      ]
    ]
    const operation = [
      { isDeletable: true },
      { isDeletable: true },
      { isDeletable: false }
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        GroupName: groupName[Math.floor(Math.random() * groupName.length)],
        Operation: operation[Math.floor(Math.random() * operation.length)],
        NetworkNamesInGroup:
          networkNamesInGroup[
          Math.floor(Math.random() * networkNamesInGroup.length)
          ]
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInGroupSetting
