
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import useGridInGroupSetting from '@/composable/useGridInGroupSetting'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import EditGroupForm from '@/components/Form/GroupSettingView/EditGroupForm.vue'
import NewGroupForm from '@/components/Form/GroupSettingView/NewGroupForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    EditGroupForm,
    NewGroupForm
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInGroupSetting()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    // Dialog 相關
    const { show: showEditGroup, toggle: toggleEditGroup } = useToggle()
    const { show: showNewGroup, toggle: toggleNewGroup } = useToggle()

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showEditGroup,
      toggleEditGroup,
      showNewGroup,
      toggleNewGroup
    }
  }
})
