<template>
  <div class="form-wrapper">
    <form>
      <div class="single-feild-group">
        <label class="feild-name">群組名稱</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 400px;"
            v-model="groupName"
          />
        </FeildInputGroup>
      </div>
      <div class="hr-divider mx-5 mb-4"></div>
      <div class="single-feild-group">
        <label class="feild-name">群組網段分類</label>
        <div>
          <div class="mb-2">
            <span
              class="d-inline-block"
              style="width: 250px; margin-right: 68px; line-height: 35px;"
              >所有網段</span
            >
            <span
              class="d-inline-block"
              style="width: 250px; line-height: 35px;"
              >此群組的網段</span
            >
          </div>
          <div>
            <div
              class="d-inline-block position-relative"
              style="width: 250px; margin-right: 68px;"
            >
              <KendoInput
                :type="'text'"
                style="width: 250px;"
                placeholder="篩選"
              />
              <div
                class="d-flex justify-content-between px-1"
                style="line-height: 32px; font-size: 12px;"
              >
                <span class="text-primary">共 4 筆篩選結果</span>
                <span class="text-secondary">已選 4 筆</span>
              </div>
              <button
                class="bg-transparent border-0 p-0 position-absolute"
                style="top: 6px; right: 5px;"
              >
                <SvgIcon icon="cross" class="text-light" />
              </button>
            </div>
            <div class="d-inline-block position-relative" style="width: 250px;">
              <KendoInput
                :type="'text'"
                style="width: 250px;"
                placeholder="篩選"
              />
              <div
                class="d-flex justify-content-between px-1"
                style="line-height: 32px; font-size: 12px;"
              >
                <span class="text-primary">共 4 筆篩選結果</span>
                <span class="text-secondary">已選 4 筆</span>
              </div>
              <button
                class="bg-transparent border-0 p-0 position-absolute"
                style="top: 6px; right: 5px;"
              >
                <SvgIcon icon="cross" class="text-light" />
              </button>
            </div>
          </div>
          <KendoListBox
            id="optional"
            :selectable="'multiple'"
            :connect-with="'selected'"
            :toolbar-tools="['transferAllTo', 'transferAllFrom']"
            style="width: 318px; height:144px;"
          >
            <option>Accounting Department</option>
            <option>Service Department</option>
            <option>Default</option>
            <option>test</option>
          </KendoListBox>
          <KendoListBox
            id="selected"
            :connect-with="'optional'"
            style="width: 250px; height:144px;"
          >
          </KendoListBox>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.listbox'
import { ListBox } from '@progress/kendo-listbox-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    KendoListBox: ListBox
  },
  setup() {
    // 群組名稱
    const groupName = ref('')
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      groupName,
      submitForm
    }
  }
})
</script>

<style scoped></style>
