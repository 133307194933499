
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.listbox'
import { ListBox } from '@progress/kendo-listbox-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    KendoListBox: ListBox
  },
  setup() {
    // 群組名稱
    const groupName = ref('Default')
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      groupName,
      submitForm
    }
  }
})
