<template>
  <div id="view-setting">
    <Topbar :backTo="{ name: 'Site' }">Taipei Office 區域設定</Topbar>
    <div class="content-container">
      <!-- 左：次導覽列 -->
      <SideNav>
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditGeneral', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GroupSetting', params: $route.params.id }"
          >網段群組設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPList', params: $route.params.id }"
          >註冊的 IP 派發來源</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditBlockMessage', params: $route.params.id }"
          >封鎖訊息 &amp; 驗證方式</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'ADAutoRegisterSetting', params: $route.params.id }"
          >AD 自動授權設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'RadiusSiteConfigSetting', params: $route.params.id }"
          >802.1x 設定</router-link
        >
      </SideNav>
      <div class="setting-container">
        <div class="main-list-view setting-list-view darken-list">
          <!-- 右側：列表容器 -->
          <div class="main-list-container">
            <div class="title-wrapper">
              <h2 class="title">網段群組設定</h2>
            </div>
            <!-- 上：控制列容器 -->
            <ControlWrapper>
              <ControlButton
                iconName="plus"
                text="新增群組"
                @click="toggleNewGroup"
              />
            </ControlWrapper>
            <!-- 下：表格容器 -->
            <GridWrapper>
              <GridPageInfo :skip="skip" :take="take" :total="total" />
              <Grid
                :style="{ height: 'calc(100vh - 350px)' }"
                :columns="columns"
                :data-items="result"
                :pageable="pageable"
                :skip="skip"
                :take="take"
                :total="total"
                @pagechange="pageChangeHandler"
                :scrollable="'true'"
                :sortable="false"
                @toggleEditGroup="toggleEditGroup"
              />
            </GridWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showEditGroup"
      :title="'Default 群組編輯'"
      :width="930"
      :height="636"
      @close="toggleEditGroup"
    >
      <EditGroupForm @close="toggleEditGroup" />
    </Dialog>
    <Dialog
      v-if="showNewGroup"
      :title="'新增網段群組'"
      :width="930"
      :height="636"
      @close="toggleNewGroup"
    >
      <NewGroupForm @close="toggleNewGroup" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import useGridInGroupSetting from '@/composable/useGridInGroupSetting'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import EditGroupForm from '@/components/Form/GroupSettingView/EditGroupForm.vue'
import NewGroupForm from '@/components/Form/GroupSettingView/NewGroupForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    EditGroupForm,
    NewGroupForm
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInGroupSetting()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    // Dialog 相關
    const { show: showEditGroup, toggle: toggleEditGroup } = useToggle()
    const { show: showNewGroup, toggle: toggleNewGroup } = useToggle()

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showEditGroup,
      toggleEditGroup,
      showNewGroup,
      toggleNewGroup
    }
  }
})
</script>

<style scoped></style>
